import React from 'react';
import styled from 'styled-components';
import Theme from '../../Theme';

export default function StorePromoBanner(props) {
  const {store, style = {}} = props;

  if (!store) {
    return null;
  } else if (!store.promo_image && !store.promo_title) {
    return null;
  }

  return (
    <Wrapper style={style}>
      {store.promo_title && (
        <div style={{display: 'flex', alignItems: 'center'}}>
          {/* <div
            style={{
              padding: 6,
              borderRadius: 8,
              backgroundColor: Theme.colors.red,
              marginRight: 10,
            }}>
            <div style={{color: 'white'}}>NEWS</div>
          </div> */}

          <h2 style={{fontWeight: '400'}}>{store.promo_title}</h2>
        </div>
      )}

      {store.promo_image && (
        <div className="img-box">
          <img src={store.promo_image} alt="store promotion" />
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 10px;

  & > .img-box {
    width: 100%;
    border-radius: 20px;
    overflow: hidden;

    & > img {
      width: 100%;
      height: 400px;
      margin-top: 10px;
      object-fit: cover;
    }
  }

  @media (max-width: 900px) {
    & > .img-box {
      & > img {
        height: 300px;
      }
    }
  }

  @media (max-width: 720px) {
    & > .img-box {
      & > img {
        height: 240px;
      }
    }
  }

  @media (max-width: 540px) {
    & > .img-box {
      & > img {
        height: 180px;
      }
    }
  }

  @media (max-width: 400px) {
    & > .img-box {
      & > img {
        height: 120px;
      }
    }
  }
`;
