import React from 'react';
import styled from 'styled-components';
import ProductItem from '../ProductItem';
import useProductGroups, {CatType} from '../../Hooks/useProductGroups';
import * as AppActions from '../../AppActions';
import qs from 'query-string';
import {useOutlet} from 'reconnect.js';
import {useSelectedStore} from '../SelectStoreModal';
import Theme from '../../Theme';
import {filterAllowedProductsBySelectedStore} from './helper';

export default function ProductList(props) {
  const [cart] = useOutlet('LayaCart');
  const [meals] = useOutlet('mealCategories');
  const selectedStore = useSelectedStore();
  const {products, mobile} = props;
  const {cat = ''} =
    (typeof window !== 'undefined' && qs.parse(window.location?.search)) || {};

  const allowedProducts = filterAllowedProductsBySelectedStore({
    products,
    selectedStore,
    meals,
  });

  const groups = useProductGroups({
    products: allowedProducts,
    catType: !cat ? CatType.PROMOTION : CatType.LABEL,
  });

  return (
    <Wrapper mobile={mobile}>
      {groups
        .filter(
          (group) =>
            group.items.length !== 0 &&
            (cat ? group.cat.name === cat : group.catL1.name === '推薦分類'),
          // && group.cat.public,
        )
        .map((group) => {
          const sortBy = group.cat.sorting || '-price';

          return (
            <div
              key={group.cat.name}
              id={group.cat.name}
              style={{marginBottom: 30}}>
              <h1 style={{fontWeight: '400'}}>{group.cat.display}</h1>
              <p style={{color: '#9A9791', marginBottom: 16}}>
                {group.cat.description || '---'}
              </p>
              <div
                style={{
                  borderRadius: 10,
                  overflow: 'hidden',
                  boxShadow: Theme.shadow,
                }}>
                {group.items
                  .sort((a, b) => {
                    if (!a.$invalid && b.$invalid) {
                      return -1; // sold out product ordering the end
                    } else if (a.$invalid && !b.$invalid) {
                      return 1; // sold out product ordering the end
                    }
                    if (sortBy === 'price') {
                      return a.price > b.price ? 1 : -1;
                    } else if (sortBy === '-price') {
                      return a.price > b.price ? -1 : 1;
                    } else {
                      // sort by name
                      return a.name > b.name ? 1 : -1;
                    }
                  })
                  .map((product, i) => {
                    return (
                      <ProductItem
                        key={product.id}
                        mobile={mobile}
                        product={product}
                        catL1={group.catL1}
                        cart={cart}
                        onClick={() => {
                          AppActions.navigate(
                            `/product/?id=${product.id}&cat=${group.cat.name}`,
                          );
                        }}
                      />
                    );
                  })}
              </div>
            </div>
          );
        })}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: ${(props) => (props.mobile ? 0 : 'var(--basePadding)')};
`;
