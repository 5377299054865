import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import * as AppActions from '../../AppActions';
import qs from 'query-string';
import CategoryMenu from '../CategoryMenu';
import {Menu} from '@styled-icons/material-rounded/Menu';
import Theme from '../../Theme';
import {getFlattenCategories, getAllowedCatL2} from '../../Utils/CatUtil';
import {navigate} from 'gatsby';

const ThemeColor = Theme.colors.main;

function getCurrentCategory() {
  if (typeof window === 'undefined') {
    return '';
  } else if (!window.location) {
    return '';
  }
  return qs.parse(window.location.search).cat || '';
}

export default function ProductCategory(props) {
  const {store, showMenu, clearShowMenuFlag} = props;
  const [promoCategories] = useOutlet('promoCategories');
  const [meals] = useOutlet('mealCategories');
  const flattenCats = getFlattenCategories('categories');
  const [showCategoryMenu, setShowCategoryMenu] = React.useState(
    !!showMenu || false,
  );

  const allowedL2CatsMap = getAllowedCatL2(store, meals);

  const cat = getCurrentCategory();

  const promoCategory = promoCategories.find((c) => c.name === '推薦分類');

  function scrollToCatL2(catL2) {
    const elem = document.getElementById(catL2);
    if (elem) {
      window.scrollTo({
        top: elem.offsetTop - 80,
        behavior: 'smooth',
      });
    }
  }

  return (
    <Wrapper>
      <div
        style={{
          display: 'flex',
          width: '100%',
          overflow: 'scroll',
          padding: '30px 20px',
        }}>
        {/* promoCategory is L1 */}
        {promoCategory && (
          <CatItem
            onClick={() => {
              navigate(`/products/`);
            }}>
            <div
              className="img-wrapper"
              style={{
                backgroundColor: !cat
                  ? Theme.colors.sub
                  : Theme.colors.bgYellow,
              }}>
              <img src={promoCategory.image} alt="category-image" />
            </div>
            <div className="name">{promoCategory.display}</div>
          </CatItem>
        )}
        {flattenCats
          .filter(
            (c) =>
              !!allowedL2CatsMap[c.name] &&
              (c.name || '').indexOf('相關') === -1,
          )
          .map((c) => {
            const [L1 = '', L2] = c.name.split('-');
            return (
              <CatItem
                key={c.name}
                onClick={() => {
                  navigate(`/products/?cat=${c.name}`);
                }}>
                <div
                  className="img-wrapper"
                  style={{
                    backgroundColor:
                      cat === c.name ? Theme.colors.sub : Theme.colors.bgYellow,
                  }}>
                  <img src={c.image} alt="category-image" />
                </div>
                <div className="name">{c.display}</div>
              </CatItem>
            );
          })}
      </div>
      {/* <TopLevel>
        <div className="content">
          {[{name: '', display: '推薦', public: true}, ...categories]
            .filter((c, idx) => {
              return idx === 0 || allowedL1CatsMap[c.name];
              // && c.public;
            })
            .map((c) => {
              const selected = c.name === t1;
              return (
                <div
                  key={c.name}
                  onClick={() => {
                    if (!c.name) {
                      AppActions.navigate(`/products/`);
                    } else {
                      AppActions.navigate(`/products/?cat=${c.name}`);
                    }
                  }}>
                  <div
                    className={'item-inner' + (selected ? ' selected ' : '')}
                    style={{color: selected ? firstCat?.darktone : '#aaa'}}>
                    {c.display}
                  </div>
                </div>
              );
            })}
        </div>
      </TopLevel> */}

      {/* <SecLevel>
        <div className="content">
          {secLevelCats
            // .filter((c) => c.public)
            .map((c) => {
              return (
                <div
                  key={c.name}
                  onClick={() => scrollToCatL2(c.name)}
                  style={{backgroundColor: firstCat?.lighttone}}>
                  <img
                    src={c.image || '/images/logo.png'}
                    alt="2nd level category"
                  />
                  <div className="info">{c.display}</div>
                </div>
              );
            })}
        </div>
      </SecLevel> */}

      <MenuIcon
        onClick={() => {
          setShowCategoryMenu(true);
        }}>
        <Menu size={36} color={'white'} />
      </MenuIcon>

      <CategoryMenu
        categories={flattenCats}
        visible={showCategoryMenu}
        setVisible={(v) => {
          setShowCategoryMenu(v);
          if (!v) {
            clearShowMenuFlag();
          }
        }}
        onCatClick={(catL2) => {
          setShowCategoryMenu(false);

          AppActions.navigate(`/products/?cat=${catL2}`);
          setTimeout(() => {
            scrollToCatL2(catL2);
          }, 800);
        }}
        onProductClick={(product) => {
          setShowCategoryMenu(false);
          AppActions.navigate(`/product/?id=${product.id}`);
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  max-width: var(--contentMaxWidth);
  margin: 0 auto;
`;

const MenuIcon = styled.div`
  position: fixed;
  bottom: 100px;
  right: 30px;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  z-index: 1;
  background-color: ${Theme.colors.main};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  transition: all 200ms;
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  }
`;

const TopLevel = styled.div`
  position: relative;
  padding-top: 16px;
  background-color: #ece9e8;

  & > .content {
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    display: flex;
    width: 100%;
    overflow-x: auto;
    padding: 0 20px;

    & > * {
      flex-shrink: 0;
    }

    & .item-inner {
      min-width: 80px;
      padding: 12px;
      text-align: center;
      cursor: pointer;
      font-weight: 600;
    }
    & .item-inner.selected {
      background-color: #fff;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
    }
  }

  /* &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 1px;
    background-color: #ccc;
    z-index: -1;
  } */
`;

const SecLevel = styled.div`
  & > .content {
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    display: flex;
    align-items: center;
    width: 100%;
    overflow-x: auto;
    padding: 10px;

    & > * {
      flex-shrink: 0;
      margin: 10px;
      border-radius: 15px;
      cursor: pointer;
      overflow: hidden;
      text-align: center;
      box-shadow: ${Theme.shadow};

      & > img {
        width: 200px;
        height: 200px;
        object-fit: contain;
      }

      & > .info {
        padding: 5px 20px 15px;
        text-align: center;
        font-size: 1.3rem;
      }
    }
  }

  @media (max-width: 480px) {
    & > .content {
      & > * {
        & > img {
          width: 120px;
          height: 120px;
        }

        & > .info {
          padding: 8px;
          font-size: 1rem;
        }
      }
    }
  }
`;

const CatItem = styled.div`
  text-align: center;
  margin-right: 20px;

  &:hover {
    cursor: pointer;
  }

  > .img-wrapper {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    margin: 0 10px 6px 10px;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    > img {
      width: 118px;
      height: 118px;
      object-fit: contain;
      transform: translateY(18px);
    }
  }
  > .name {
    color: ${Theme.colors.text};
    font-size: 16px;
  }
`;
