import React from 'react';
import Theme from '../../Theme';
import {getOutlet} from 'reconnect.js';
import HotProducts from '../HotProducts';
import * as Ant from 'antd';
import {Search} from '@styled-icons/evil/Search';
import * as AppActions from '../../AppActions';
import Modal from '../Modal';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import * as LocalProductHistoryUtil from '../../Utils/LocalProductHistoryUtil';
import * as LocalProductSearchHistoryUtil from '../../Utils/LocalProductSearchHistoryUtils';
import {filterAllowedProductsBySelectedStore} from '../ProductList/helper';
import {useSelectedStore} from '../SelectStoreModal';
import ProductItem from '../ProductItem';

export default function SearchPanel(props) {
  const {visible, setVisible} = props;

  return (
    <Modal visible={visible} onClose={() => setVisible(false)}>
      <SearchPanelContent onClose={() => setVisible(false)} />
    </Modal>
  );
}

function SearchPanelContent(props) {
  const {onClose} = props;
  const [keyword, setKeyword] = React.useState('');
  const hashtags = getOutlet('hotsearch').getValue();
  const [localSavedProducts] = useOutlet('local-product-history');
  const [searched] = useOutlet('local-search-history');
  const [products, setProducts] = React.useState([]);
  const [cart] = useOutlet('LayaCart');
  const [meals] = useOutlet('mealCategories');
  const selectedStore = useSelectedStore();

  React.useEffect(() => {
    (async () => {
      try {
        let resp = await AppActions.fetchProducts();
        setProducts(resp);
      } catch (e) {}
    })();

    LocalProductHistoryUtil.loadLocalProductHistory();
    LocalProductSearchHistoryUtil.loadLocalSearchHistory();
  }, []);

  let searchResults = React.useMemo(() => {
    let allowedProducts = filterAllowedProductsBySelectedStore({
      products,
      selectedStore,
      meals,
    });

    if (keyword) {
      return allowedProducts.filter((p) => {
        if (p.name.indexOf(keyword) > -1) {
          return true;
        }

        const hashtags = p?.hashtags || [];
        if (hashtags.indexOf(keyword) > -1) {
          return true;
        }

        return false;
      });
    }
    return [];
  }, [products, keyword]);

  return (
    <SearchPanelWrapper>
      <div className="block row" style={{marginTop: 56}}>
        <div className="search-bar">
          <Search size={26} color="#666" />
          <div
            style={{
              alignSelf: 'stretch',
              height: 1,
              backgroundColor: '#666',
            }}
          />
          <Ant.Input
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            style={{flex: 1, margin: '0 6px'}}
            placeholder="你今天想吃什麼？"
          />
          {/* <Ant.Button type="submit" htmlType="submit">
            送出
          </Ant.Button> */}
        </div>
        {keyword && (
          <Ant.Button style={{marginLeft: 10}} onClick={() => setKeyword('')}>
            清除
          </Ant.Button>
        )}
      </div>

      {keyword && searchResults.length > 0 ? (
        <div className="block">
          <div
            style={{
              borderRadius: 10,
              overflow: 'hidden',
              boxShadow: Theme.shadow,
            }}>
            {searchResults.map((product) => (
              <ProductItem
                key={product.id}
                product={product}
                cart={cart}
                catL1={{lighttone: '#fff2d9'}}
                onClick={async () => {
                  AppActions.navigate(`/product/?id=${product.id}`);
                  LocalProductSearchHistoryUtil.addToLocalHistory(product);
                }}
              />
            ))}
          </div>
        </div>
      ) : (
        <React.Fragment>
          {keyword && searchResults.length === 0 ? (
            <div className="block" style={{color: '#FF0000'}}>
              找不到包含 {keyword} 關鍵字的商品...
            </div>
          ) : null}

          <div className="block">
            <div className="title">換換口味，來點不一樣</div>
            <HotProducts
              max={3}
              onClick={(product) => {
                AppActions.navigate(`/product/?id=${product.id}`);
                onClose();
              }}
            />
          </div>

          <div className="block">
            <div className="title">看看大家都點什麼</div>
            <div className="grid">
              {hashtags.slice(0, 6).map((t) => {
                return (
                  <div
                    key={t.name}
                    className="button"
                    onClick={() => {
                      setKeyword(t.name);
                    }}>
                    {t.name}
                  </div>
                );
              })}
              <div className="button" style={{border: 0}} />
              <div className="button" style={{border: 0}} />
            </div>
          </div>

          <div className="block">
            <div className="title">你曾經搜尋過想點的餐</div>
            {products.length > 0 && searched.length > 0 ? (
              searched.map((id) => {
                let p = products.find((p) => p.id === id);

                return (
                  <div className="row" style={{marginBottom: 8}}>
                    <div
                      className="row"
                      style={{flex: 1}}
                      onClick={() => {
                        if (p) {
                          AppActions.navigate(`/product/?id=${p.id}`);
                          onClose();
                        }
                      }}>
                      <div className="circle-img-wrapper">
                        <img
                          src={p?.images?.[0]?.expected_url}
                          alt="product-image"
                        />
                      </div>
                      <div
                        style={{
                          fontSize: 16,
                          color: p ? Theme.colors.text : '#bbb',
                        }}>
                        {p ? p.name : '已下架商品'}
                      </div>
                    </div>
                    <img
                      src="/images/icon-close-black.png"
                      style={{width: 30, height: 30, padding: 5}}
                      onClick={() =>
                        LocalProductSearchHistoryUtil.deleteLocalHistory(id)
                      }
                    />
                  </div>
                );
              })
            ) : (
              <div style={{color: '#AEAEAE'}}>目前無紀錄</div>
            )}
          </div>

          <div className="block">
            <div className="title">今天，一樣嗎？</div>
            {products.length > 0 && localSavedProducts.length > 0 ? (
              localSavedProducts.map((id) => {
                let p = products.find((p) => p.id === id);

                return (
                  <div className="row" style={{marginBottom: 8}}>
                    <div
                      className="row"
                      style={{flex: 1}}
                      onClick={() => {
                        if (p) {
                          AppActions.navigate(`/product/?id=${p.id}`);
                          onClose();
                        }
                      }}>
                      <div className="circle-img-wrapper">
                        <img
                          src={p?.images?.[0]?.expected_url}
                          alt="product-image"
                        />
                      </div>
                      <div
                        style={{
                          fontSize: 16,
                          color: p ? Theme.colors.text : '#bbb',
                        }}>
                        {p ? p.name : '已下架商品'}
                      </div>
                    </div>
                    <img
                      src="/images/icon-close-black.png"
                      style={{width: 30, height: 30, padding: 5}}
                      onClick={() =>
                        LocalProductHistoryUtil.deleteLocalHistory(id)
                      }
                    />
                  </div>
                );
              })
            ) : (
              <div style={{color: '#AEAEAE'}}>目前無紀錄</div>
            )}
          </div>
        </React.Fragment>
      )}
    </SearchPanelWrapper>
  );
}

const SearchPanelWrapper = styled.div`
  color: ${Theme.colors.text};

  .search-bar {
    display: flex;
    align-items: center;
    height: 42px;
    flex: 1;
  }
  .block {
    padding: 0 20px;
    margin-bottom: 20px;
    > .grid {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      gap: 18px;

      > .button {
        border-radius: 14px;
        padding: 6px;
        text-align: center;
        border: solid 1px #848484;
        color: #848484;
        flex: 1 0 calc((100% - 18px * 2) / 3);
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .title {
    font-size: 20px;
    padding: 16px 0;
    color: #000;
    font-weight: bold;
  }
  .row {
    display: flex;
    align-items: center;
  }
  .circle-img-wrapper {
    width: 60px;
    height: 60px;
    background-color: #e5e5e5;
    border-radius: 30px;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    > img {
      width: 80px;
      height: 80px;
      object-fit: contain;
      transform: translateY(14px);
    }
  }
`;
