import React from 'react';
import styled from 'styled-components';
import {Plus} from '@styled-icons/feather/Plus';
import * as AppActions from '../../AppActions';
import {useOutlet} from 'reconnect.js';
import {useSelectedStore} from '../../Components/SelectStoreModal/helper';
import {filterAllowedProductsBySelectedStore} from '../ProductList/helper';

export default function HotProducts(props) {
  const {onClick, max} = props;
  const [landing] = useOutlet('landing');
  const [products, setProducts] = React.useState([]);
  const store = useSelectedStore();
  const [meals] = useOutlet('mealCategories');

  React.useEffect(() => {
    async function fetchData() {
      await AppActions.delay(300);
      setProducts(await AppActions.fetchProducts());
    }

    fetchData();
  }, []);

  const hotProducts = landing?.hot_products || [];

  const allowedProducts = filterAllowedProductsBySelectedStore({
    products,
    selectedStore: store,
    meals,
  })
    .filter((p) => {
      return hotProducts.find((pp) => pp.product === p.id);
    })
    .sort((a, b) => {
      return (
        hotProducts.findIndex((p) => p.product === a.id) -
        hotProducts.findIndex((p) => p.product === b.id)
      );
    });

  return (
    <WrapperNext>
      {allowedProducts.slice(0, max ? max : allowedProducts.length).map((p) => {
        const imageUrl = (p?.images || [])[0]?.expected_url;

        return (
          <div className="item" key={p.id} onClick={() => onClick(p)}>
            <img src={imageUrl} />
            <div className="name">{p.name}</div>
          </div>
        );
      })}
      <div className="item" />
      <div className="item" />
    </WrapperNext>
  );
}

const WrapperNext = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 18px;

  & > .item {
    flex: 1 0 calc((100% - 18px * 2) / 3);
    & > img {
      width: 100%;
      min-height: 90px;
      background-color: #ffe6a2;
      border-radius: 12px;
      margin-bottom: 6px;
      object-fit: contain;
    }
    & > .name {
      font-size: 16px;
    }
    & > .price {
      text-align: right;
      font-size: 18px;
    }
    &:hover {
      cursor: pointer;
    }
  }
`;
