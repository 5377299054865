import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import * as AppActions from '../../AppActions';
import {useSelectedStore} from '../../Components/SelectStoreModal';
import ProductCategory from '../../Components/ProductCategory';
import LayaProductList from '../../Components/ProductList';
import StorePromoBanner from '../../Components/StorePromoBanner';
import Theme from '../../Theme';
import {Search} from '@styled-icons/evil/Search';
import SearchPanel from '../../Components/SearchPanel';
import qs from 'query-string';
import {getAllowedCatL2} from '../../Utils/CatUtil';

function ProductList(props) {
  const {cat = '', showMenu} = qs.parse(props.location.search) || {};
  const [variantGroups] = useOutlet('variantGroups');
  const [meals] = useOutlet('mealCategories');
  const [products, setProducts] = React.useState([]);
  const [showSearchPanel, setShowSearchPanel] = React.useState(false);
  const selectedStore = useSelectedStore();

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true, {visible: false});
      try {
        setProducts(await AppActions.fetchProducts());
      } catch (ex) {
        console.warn('ProductList.useEffect ex', ex);
      }
      AppActions.setLoading(false);
    }

    if (variantGroups.length > 0) {
      fetchData();
    }
  }, [variantGroups, selectedStore?.id]);

  function clearShowMenuFlag() {
    const params = qs.parse(props.location.search) || {};
    delete params.showMenu;
    let q = '';
    for (const k in params) {
      q += `${k}=${params[k]}`;
    }
    AppActions.navigate(`/products/?` + q);
  }

  React.useEffect(() => {
    if (cat && selectedStore) {
      const allowedL2CatsMap = getAllowedCatL2(selectedStore, meals);
      if (!allowedL2CatsMap[cat]) {
        const params = qs.parse(props.location.search) || {};
        delete params.cat;
        let q = '';
        for (const k in params) {
          q += `${k}=${params[k]}`;
        }
        AppActions.navigate(`/products/?` + q);
      }
    }
  }, [cat, selectedStore, meals]);

  return (
    <Wrapper id="rev-product-list">
      <ProductCategory
        store={selectedStore}
        showMenu={showMenu}
        clearShowMenuFlag={clearShowMenuFlag}
      />

      <div>
        <div className="has-max-width">
          {(!cat && selectedStore && (
            <StorePromoBanner store={selectedStore} style={{padding: 10}} />
          )) || <div style={{height: 5}} />}
          <LayaProductList products={products} />
        </div>
      </div>

      <SearchIcon
        onClick={() => {
          setShowSearchPanel(true);
        }}>
        <Search size={40} color="white" />
      </SearchIcon>

      <SearchPanel visible={showSearchPanel} setVisible={setShowSearchPanel} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: 64px;
  margin: 0 auto;

  & .has-max-width {
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
  }
`;

const SearchIcon = styled.div`
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: ${Theme.colors.main};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  transition: all 200ms;
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  }
`;

export default ProductList;
