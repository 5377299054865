import React from 'react';
import styled from 'styled-components';
import {ProductAttrTags, ProductPromoTags} from './ProductMisc';
import {CheckCircle} from '@styled-icons/bootstrap/CheckCircle';
import Theme from '../../Theme';
import {getItemQtyInCart} from '../../Utils/CartUtil';
import * as PriceUtil from '../../Utils/PriceUtil';
import {useSelectedStore} from '../../Components/SelectStoreModal/helper';
import {useOutlet} from 'reconnect.js';

export default function ProductItem(props) {
  const {product, cart, onClick, catL1} = props;
  const store = useSelectedStore();

  const src = React.useMemo(() => {
    try {
      return product.images[0].expected_url;
    } catch (e) {
      return null;
    }
  }, [product.images]);

  const itemQtyInCart = getItemQtyInCart(cart, product);
  const [variantGroups] = useOutlet('variantGroups');
  const variantGroup = variantGroups.find((vg) => vg.name === product?.group);

  return (
    <Wrapper
      onClick={
        product.$invalid ? () => alert('目前所選店家該餐點補貨中') : onClick
      }>
      <div className="product-img" style={{backgroundColor: catL1?.lighttone}}>
        <img src={src || '/images/logo.png'} alt="product" loading="lazy" />
      </div>

      <div className="info">
        <div className="tag-bar">
          <ProductPromoTags product={product} />
          <ProductAttrTags product={product} />
        </div>
        <div style={{display: 'flex', alignItems: 'center'}}>
          {product.$invalid && (
            <div
              style={{
                marginRight: 10,
                padding: 8,
                backgroundColor: '#eee',
                color: 'red',
                borderRadius: 4,
                fontWeight: 'bold',
              }}>
              補貨中
            </div>
          )}

          <h3>{product.name}</h3>
        </div>
        <h3 className="light">{product.english_name}</h3>
        <div className="price-bar">
          <div className="price">
            {PriceUtil.displayPrice(product.price, store, {
              multipleOf: variantGroup?.price_delta_multiple_of,
            })}
          </div>
          {product.original_price && product.original_price !== product.price && (
            <div className="original-price">
              {PriceUtil.displayPrice(product.original_price, store, {
                multipleOf: variantGroup?.price_delta_multiple_of,
              })}
            </div>
          )}
        </div>
      </div>

      {itemQtyInCart > 0 && (
        <div className="qty-in-cart">
          <CheckCircle color="white" size={16} />
          <span className="text">已加入購物車({itemQtyInCart})</span>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  transition: all 200ms;
  position: relative;
  background-color: ${Theme.colors.bgYellow};

  &:not(:last-child) {
    border-bottom: solid 1px #ccc;
  }

  &:hover {
    background-color: #eee;
  }

  & > .product-img {
    flex: 0 0 240px;
    padding: 12px;
    & img {
      width: 240px;
      height: 180px;
      object-fit: contain;
    }
  }

  & > .info {
    margin-left: 20px;
    flex: 1;

    & h3 {
      font-size: 1.2rem;
      font-weight: 400;

      &.light {
        font-size: 16px;
      }
    }

    & .price-bar {
      display: flex;
      align-items: center;
      & .price {
        font-weight: 600;
        font-size: 1.2rem;
      }
      & .original-price {
        margin-left: 10px;
        font-size: 1rem;
        font-weight: 600;
        color: #aaa;
        text-decoration: line-through;
      }
    }

    & .tag-bar {
      margin-top: 20px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      & > * {
        margin-bottom: 10px;
        margin-right: 10px;
      }
    }
  }

  & .qty-in-cart {
    position: absolute;
    top: 6px;
    left: 6px;
    padding: 6px 12px;
    background-color: orange;
    opacity: 0.75;
    border-radius: 32px;
    display: flex;
    align-items: center;

    & > .text {
      margin-left: 5px;
      color: white;
    }
  }

  @media (max-width: 600px) {
    & > .product-img {
      flex: 0 0 150px;
      & img {
        width: 150px;
        height: 100px;
        object-fit: contain;
      }
    }
  }
`;
